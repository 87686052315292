<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <v-card class="border-radius-xl card-shadow">
            <div class="px-4 py-4 mt-2">
              <LessonTableTMP
                :path="path"
                :headerNames="['Анги', 'Xичээлийн нэр', 'Төрөл', 'Үйлдэл']"
                :addButtonText="'Xичээл'"
              >
              </LessonTableTMP>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { sync } from "vuex-pathify";
import LessonTableTMP from "./Widgets/LessonTableTMP.vue";
const fb = require("@/firebaseConfig.js");
export default {
  name: "smart-home",
  components: {
    LessonTableTMP,
  },
  // props: {
  //   path: {
  //     type: String,
  //     required: true,
  //   },
  // },
  computed: {
    ...sync("*"),
    yyschool() {
      return fb.yyschool;
    },
  },
  data() {
    return {
      lessons: null,
      path: null,
      tab: null,
      tabClassGroups: null,
      tabTexts: ["Сэдвүүд", "Даалгавар", "Дүн"],
    };
  },
  methods: {},
  created() {
    this.path = this.yyschool + "lessons";
  },
  mounted() {},
};
</script>

 