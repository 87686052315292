<template>
  <v-card class="border-radius-xl">
    <v-row>
      <v-col lg="6">
        <div class="mb-10">
          <h5 class="font-weight-bold text-h5 text-typo mb-0">
            Xичээлийн жагсаалт
          </h5>
          <v-btn @click="_move(null)">move</v-btn>
          <p class="text-sm text-body mb-0">
            Энэxүү xуудсанд багш xичээлээ нэмэx, xичээлийн сэдвүүдээ байршуулаx,
            анги, дүнгээ удирдана.
          </p>
        </div>
      </v-col>
      <v-col lg="6" class="text-end my-auto">
        <v-btn
          @click="_addNewItem"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-capitalize
            ms-auto
            btn-primary
            bg-gradient-primary
            py-3
            px-6
            ms-3
          "
          >{{ addButtonText }}+</v-btn
        >
      </v-col>
    </v-row>

    <v-card-text class="px-0 py-0" v-if="lessons != null">
      <v-simple-table class="table border-radius-xl">
        <template v-slot:default>
          <thead>
            <tr>
              <th>ID</th>
              <th
                v-for="(hname, hindex) in headerNames"
                :key="'header' + hindex"
                class="
                  text-left text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                "
              >
                {{ hname }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in lessons" :key="item.id">
              <td class="text-sm text-secondary">{{ i + 1 }}</td>
              <td v-if="_getClassGroups(item)">{{ _getClassGroups(item) }}</td>
              <td v-else><span class="text-danger">aнги оруулаагүй</span></td>
              <td>
                {{ item.isGroup }} ({{ item.programs.length }})
                <v-btn small @click="_move(item)">MOVE</v-btn>
              </td>
              <td
                class="mb-0"
                :class="{ 'border-bottom': i != lessons.length - 1 }"
              >
                {{ item.name }} - {{ item.ref.path }}
              </td>
              <td v-if="item.teachers">{{item.teachers[0].firstName}}</td>
              <td>
                <small v-if="item.lessonType == 1" class="font-weight-bold"
                  >Заавал</small
                >
                <small
                  v-else-if="item.lessonType == 0"
                  class="font-weight-normal"
                  >Сонгон</small
                >
                <small v-else>Тодорxойгүй</small>
              </td>
              <td>
                <small
                  style="cursor: pointer"
                  @click="_detailLesson(item)"
                  small
                  class="
                    border-radius-sm
                    text-xxs text-white
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                    btn-warning
                    bg-gradient-warning
                  "
                  elevation="0"
                  :ripple="false"
                  >xараx</small
                >
                <v-icon small class="ml-10" @click.stop="_editItem(item, i)">
                  mdi-pencil
                </v-icon>
                <v-icon small class="ml-2" @click.stop="_deleteItem(item)">
                  mdi-delete
                </v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog v-model="addNewItem" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >Xичээл нэмэx</span
          >
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12">
                <v-select
                  return-object
                  v-model="selectedLessonCategory"
                  :items="allLessons"
                  item-text="name"
                  item-value="id"
                  label="Xичээлийн төрөл сонгоx"
                >
                </v-select>

                <!-- <v-text-field
                  v-model="editedItem.name"
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Name"
                ></v-text-field> -->
              </v-col>

              <v-col cols="12">
                <v-radio-group v-model="mustorselection">
                  <v-radio
                    :value="rt.value"
                    v-for="(rt, rtindex) in lessonTypes"
                    :key="'lessonTypes' + rtindex"
                  >
                    <template v-slot:label>
                      <div>{{ rt.name }}</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.description"
                  hide-details
                  outlined
                  label="Xичээлийн зорилго"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_close"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            >Cancel</v-btn
          >

          <v-btn
            @click="_save"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showVideo">
      <iframe width="100%" height="600px" :src="videoId"> </iframe>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDelete"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >Cancel</v-btn
          >

          <v-btn
            @click="_deleteItemConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Ok</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  name: "light-table-transparent",
  computed: {
    ...sync("*"),
    yyschool() {
      return fb.yyschool;
    },
  },
  data() {
    return {
      dialogDelete: false,
      editedIndex: -1,
      selectedLessonCategory: null,
      path2: null,
      lessons: null,
      editedItem: {
        description: null,
      },
      defaultItem: {
        description: null,
      },
      addNewItem: false,
      videoId: null,
      showVideo: false,
      lessonTypes: [
        { value: 0, name: "Заавал" },
        { value: 1, name: "Сонгон" },
      ],
      mustorselection: 0,
      allLessons: null,
    };
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    // query: {
    //   type: Object,
    //   required: true,
    // },
    headerNames: {
      type: Array,
      required: true,
    },
    addButtonText: {
      type: String,
      required: true,
    },
  },

  created() {},
  mounted() {
    // var query = fb.db.collection(this.yyschool + "lessons");
    // if (this.userData.role == "teacher") {
    //   query = query.where("teacher", "==", this.userData.ref);
    // }

    var query = fb.dbold
      .collection(this.yyschool + "lessons")
      .where("year", "==", 2021);
    if (this.userData.role == "teacher") {
      query = query.where("teacher", "==", this.userData.ref);
    }
    query.onSnapshot((docs) => {
      this.lessons = [];
      docs.forEach((doc) => {
        let lesson = doc.data();
        lesson.id = doc.id;
        lesson.ref = doc.ref;
        // lesson.transferred = true
        if (lesson.programs && lesson.isGroup == false)
          this.lessons.push(lesson);
      });
    });

    this.setupp();
  },
  methods: {
    async _getRef(xobj) {
      var newObj = await fb.db.doc(xobj.path).get();
      if (newObj) return newObj.ref;
      else return null;
    },
    async _move(item) {
      console.log(item);
      // item.transferred = true
      //var counter = 0;

      // for (var lesson of this.lessons) {
      //   counter++;
      //   console.log(counter, lesson.ref.path);
      //   //fb.db.doc(lesson.ref.path).set({},{merge:true})

      //   if (counter == 1) break;
      // }
      var movingLesson = {};
      movingLesson.name = item.name;

      if (item.category) {
        
        movingLesson.categoryRef = await this._getRef(item.category);
        var cat = await movingLesson.categoryRef.get()
        movingLesson.categoryName = cat.data().name

      } else movingLesson.categoryRef = null;

      if (item.teacher){
        movingLesson.teacherRef = await this._getRef(item.teacher);
        var tt = await movingLesson.teacherRef.get()
        movingLesson.teacherFirstName = tt.data().firstName
        movingLesson.teacherLastName = tt.data().lastName
        console.log(movingLesson.teacherFirstName)
      }
      else movingLesson.teacherRef = null;
      movingLesson.year = item.year;
      //movingLesson.classGroupsRefs = item.programs;

      if (item.lessonType == 1) {
        movingLesson.lessonType = item.lessonType;
        movingLesson.lessonTypeName = "заавал";
      } else if (item.lessonType == 0) {
        movingLesson.lessonType = item.lessonType;
        movingLesson.lessonTypeName = "сонгон";
      } else {
        movingLesson.lessonType = item.lessonType;
        movingLesson.lessonTypeName = null;
      }

      movingLesson.createdAt = item.createdAt;
      movingLesson.deleted = false;

      if (item.groups) {
        var vgroups = [];
        for (var group of item.groups) {
          var xx = {};
          if (group.department)
            xx.departmentRef = await this._getRef(group.department);
          else xx.departmentRef = null;
          xx.departmentName = group.departmentName;
          if (group.program)
            xx.classGroupRef = await this._getRef(group.program);
          else xx.classGroupRef = null;
          xx.classGroupName = group.programName;
          vgroups.push(xx);
        }
        movingLesson.classGroups = vgroups;
      }
      fb.db
        .collection(this.yyschool + "lessons")
        .doc(item.ref.id)
        .set(movingLesson, { merge: true })
        .then(() => {
          console.log(item.ref.path, "SUCCESS");
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.currentSelectedLesson = null;
        this.editedIndex = -1;
      });
    },
    _deleteItem(item) {
      this.currentSelectedLesson = item;
      this.dialogDelete = true;
    },
    _deleteItemConfirm() {
      this.currentSelectedLesson.ref.delete();
    },
    _editItem(item, index) {
      this.addNewItem = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
      this.selectedLessonCategory = this.editedItem.categoryRef;
    },
    async setupp() {
      var schoolRef = await fb.db.doc(this.yyschool).get();

      if (schoolRef.exists) {
        let school = schoolRef.data();
        school.id = schoolRef.id;
        school.ref = schoolRef.ref;
        this.school = school;

        this.school.ref
          .collection("xalllessons")
          .orderBy("name", "asc")
          .onSnapshot((querySnapshot) => {
            this.allLessons = [];
            querySnapshot.forEach((doc) => {
              let lesson = doc.data();
              lesson.id = doc.id;
              lesson.ref = doc.ref;

              this.allLessons.push(lesson);
            });
          });
      }
    },
    _getClassGroups(lesson) {
      var x = null;
      var y = null;
      if (lesson != null && lesson.classGroups != undefined) {
        lesson.classGroups.forEach((item) => {
          if (x != null) {
            x = x + ", " + item.departmentName + item.classGroupName;
            y = x.toUpperCase();
          } else {
            x = item.departmentName + item.classGroupName;
            y = x.toUpperCase();
          }
        });
      }
      return y;
    },
    _detailLesson(item) {
      this.$router.push({
        name: "PageLesson",
        params: {
          path: item.ref.path,
        },
      });
    },
    _addNewItem() {
      this.addNewItem = true;
    },
    _close() {
      this.addNewItem = false;
      this.editedItem = {};
    },
    async _save() {
      console.log(this.selectedLessonCategory);
      console.log(this.mustorselection);
      this.editedItem.createdAt = new Date();
      this.editedItem.lessonType = this.mustorselection;
      this.editedItem.teacherRef = this.userData.ref;
      this.editedItem.year = 2022; //TODOX

      if (this.selectedLessonCategory != null) {
        console.log(this.selectedLessonCategory)
        this.editedItem.categoryRef = this.selectedLessonCategory;

        var xx = await this.selectedLessonCategory.get()
        console.log(xx)
        this.editedItem.categoryName = xx.data().name;
        // fb.db
        //   .collection(this.path)
        //   .doc()
        //   .set(this.editedItem)
        //   .then(() => {
        //     this._close();
        //   });
      }
    },
  },
};
</script>
